









import Vue from 'vue';
import Header from '@/components/Header.vue'; // @ is an alias to /src

export default Vue.extend({
	name: 'App',
	components: { Header },
	data: () => ({})
});
