var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vs_home"},[_c('v-carousel',{attrs:{"cycle":"","continuous":"","interval":15000,"hide-delimiters":"","height":_vm.imgHeight,"show-arrows":false}},_vm._l((_vm.items),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":item.src,"reverse-transition":"fade-transition","transition":"fade-transition"}},[_c('v-container',{staticClass:"vs_margintopheader pr-3"},[_c('v-row',[_c('v-col',{staticClass:"col-xs-12 col-sm-10 col-md-8 col-lg-7 col-xl-6"},[_c('v-card',{staticClass:"elevation-0",class:{
								'mx-0': _vm.$vuetify.breakpoint.smAndDown,
								'ma-5': _vm.$vuetify.breakpoint.mdAndUp,
								'mt-0': _vm.$vuetify.breakpoint.smAndDown,
								'pa-5': _vm.$vuetify.breakpoint.mdAndUp
							},attrs:{"color":"transparent"}},[_c('v-card-title',{staticClass:"vs_hello  mb-3",class:{
									'mx-0': _vm.$vuetify.breakpoint.smAndDown,
									'mx-5': _vm.$vuetify.breakpoint.mdAndUp,
									'mt-0': _vm.$vuetify.breakpoint.smAndDown,
									'mt-4': _vm.$vuetify.breakpoint.mdAndUp
								}},[_vm._v("Hey, I'm Vicent")]),_c('v-card-text',{staticClass:"vs_description",class:{
									'ma-0': _vm.$vuetify.breakpoint.smAndDown,
									'ma-5': _vm.$vuetify.breakpoint.mdAndUp
								}},[_vm._v("GIS developer & travel lover based in Valencia, Spain ")]),_c('v-card-actions',{staticClass:"vs_description ma-5 pl-5"},[_c('v-btn',{staticClass:"mr-5 elevation-0 vs_btn_more",attrs:{"large":"","color":"rgba(255,255,255,0.8)","aria-label":"Learn more"},on:{"click":_vm.goBackground}},[_vm._v(" Learn more ")])],1)],1)],1)],1)],1),_c('Credits',{attrs:{"link":item.link,"name":item.credits,"place":item.place}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }