



































import { Component, Vue } from 'vue-property-decorator';
import mobileUtils from '@/mixins/mobileUtils';
import Menu from '@/components/Menu.vue';

@Component({
	mixins: [mobileUtils],
	components: {
		Menu
	},
	computed: {
		isHome(): boolean {
			return this.$store.getters.currentTab === 'home';
		},
		height(): object {
			return (this as any).isMobile ? this.$store.getters.headerHeight.mobile : this.$store.getters.headerHeight.desktop;
		},
		isDrawer(): boolean {
			return this.$store.getters.drawer;
		}
	},
	methods: {
		goHome(): void {
			this.$store.commit('changeCurrentTab', 'home');
			this.$router.push({ path: '/' });
		},
		goTwitter(): void {
			window.open('https://twitter.com/visancal');
		},
		goInstagram(): void {
			window.open('https://www.instagram.com/visancal/');
		},
		goLinkedin(): void {
			window.open('https://www.linkedin.com/in/vsanjaime/');
		},
		goGithub(): void {
			window.open('https://github.com/visancal');
		}
	}
})
export default class Header extends Vue {}
