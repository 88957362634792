import { render, staticRenderFns } from "./Menu.vue?vue&type=template&id=464d0d71&scoped=true&"
import script from "./Menu.vue?vue&type=script&lang=ts&"
export * from "./Menu.vue?vue&type=script&lang=ts&"
import style0 from "./Menu.vue?vue&type=style&index=0&id=464d0d71&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "464d0d71",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VAppBarNavIcon,VBtn,VList,VListItem,VListItemGroup,VListItemTitle,VNavigationDrawer,VRow,VSubheader})
