
































































import { Component, Vue } from 'vue-property-decorator';
import mobileUtils from '@/mixins/mobileUtils';

@Component({
	data: function() {
		return {
			drawer: false,
			group: 1
		};
	},
	mixins: [mobileUtils],
	props: {},
	methods: {
		goHome(): void {
			if (this.$router.currentRoute.name !== 'home') {
				this.$store.commit('changeCurrentTab', 'home');
				this.$router.push({ path: '/' });
			}
		},
		goBackground(): void {
			if (this.$router.currentRoute.name !== 'background') {
				this.$store.commit('changeCurrentTab', 'background');
				this.$router.push({ path: 'background' });
			}
		},
		goProjects(): void {
			if (this.$router.currentRoute.name !== 'projects') {
				this.$store.commit('changeCurrentTab', 'projects');
				this.$router.push({ path: 'projects' });
			}
		},
		goTrips(): void {
			if (this.$router.currentRoute.name !== 'trips') {
				this.$store.commit('changeCurrentTab', 'trips');
				this.$router.push({ path: 'trips' });
			}
		}
	},
	computed: {
		selectedTab(): string {
			return this.$store.getters.currentTab;
		},
		isHome(): boolean {
			return this.$store.getters.currentTab === 'home';
		}
	},
	created() {
		if (this.$router.currentRoute.name === 'background') {
			(this as any).group = 1;
		} else if (this.$router.currentRoute.name === 'projects') {
			(this as any).group = 2;
		} else {
			(this as any).group = 0;
		}
	},
	watch: {
		group() {
			(this as any).drawer = false;
		}
	}
})
export default class Menu extends Vue {}
