
























































import { Component, Vue } from 'vue-property-decorator';
import Credits from '@/components/Credits.vue';

@Component({
	components: {
		Credits
	},
	data() {
		return {
			items: [
				{
					src: require('../assets/home/intro.webp'),
					credits: '@xurxosanz',
					link: 'https://twitter.com/xurxosanz',
					place: 'Griffith Observatory, LA'
				},
				{
					src: require('../assets/home/intro2.webp'),
					credits: '@xurxosanz',
					link: 'https://twitter.com/xurxosanz',
					place: 'Badwater Basin, Death Valley, NE'
				}
			]
		};
	},
	computed: {
		imgHeight() {
			return Math.ceil(window.innerHeight);
		}
	},
	methods: {
		goBackground() {
			this.$store.commit('changeCurrentTab', 'background');
			this.$router.push({ path: 'background' });
		}
	},
	created() {
		this.$store.commit('changeCurrentTab', this.$route.name);
	}
})
export default class Home extends Vue {}
