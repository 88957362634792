















import { Component, Vue } from 'vue-property-decorator';

@Component({
	data: function() {
		return {};
	},
	props: {
		name: String,
		link: String,
		place: String
	}
})
export default class Credits extends Vue {}
